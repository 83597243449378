html, body {
  width: 100%;
  margin: 0;
  background: #fff;
  font-family: helvetica, arial, sans-serif;
}

#root {
  display: flex;
  justify-content: center;
}